import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Slider from "react-slick";
import Header from "../../maincomponents/Header";
import Footer from "../../maincomponents/Footer";
import SafeHtmlComponent from "../main/SafeHtmlComponent";
import Spinner from "../main/Spinner";

const relatedSettings = {
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  dots: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Blogdetail = () => {
  const { postId } = useParams();
  const [blogPost, setBlogPost] = useState(null);
  const [relatedBlogs, setRelatedBlogs] = useState([]); // State for related blogs
  const [allPosts, setAllPosts] = useState([]); // For next and previous navigation
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchBlogPost = async () => {
      try {
        const response = await axios.get(
          `https://saaskin.com/api/auth/blog-detail/${postId}/`
        );
        setBlogPost(response.data);
        setRelatedBlogs(response.data.related_products || []); // Set related blogs from API response
        fetchAllPosts(); // Fetch all posts for next and previous navigation
      } catch (error) {
        console.error("Error fetching blog post:", error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    const fetchAllPosts = async () => {
      try {
        const response = await axios.get(`https://saaskin.com/api/auth/blogs/`);
        setAllPosts(response.data);
      } catch (error) {
        console.error("Error fetching all blog posts:", error);
      }
    };

    fetchBlogPost();
  }, [postId]);

  if (loading) return <Spinner />;
  if (error) return <p>Error loading blog post. Please try again later.</p>;

  const currentIndex = allPosts.findIndex((post) => post.id === Number(postId));
  const nextPost = allPosts[currentIndex + 1];
  const prevPost = allPosts[currentIndex - 1];

  return (
    <div>
      <Header />
      <div className="row justify-content-center mt-3" id="blog-section">
        <div className="col-md-8 text-center">
          <h2 className="text-black h1 site-section-heading text-center">
            Blog Detail
          </h2>
        </div>
      </div>
      <div className="col-lg-12 d-flex justify-content-center align-items-center mt-3">
        <div
          className="col-md-6 col-lg-8 mb-5 mb-lg-4 mx-2 py-3 d-flex justify-content-center align-items-center"
          style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
        >
          <div className="h-entryblog container ">
            <h2 className="font-size-regular text-center mt-1">
              {blogPost.title}
            </h2>
            <div className="meta mb-4 px-5 mt-2">
              <p
                id="blogdetailcategory"
                style={{ fontSize: "18px", margin: "5px" }}
              >
                <Link>{blogPost.blogSubcategory_name}</Link>
              </p>
              <div
                className="meta"
                style={{ color: "black", fontSize: "18px" }}
              >
                <p>
                  <span
                    style={{
                      color: "black",
                      marginRight: "5px",
                      fontStyle: "italic",
                    }}
                  >
                    Published:
                  </span>
                  <span style={{ fontStyle: "italic" }}>
                    {new Date(blogPost.posted_date).toLocaleDateString(
                      "en-US",
                      {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      }
                    )}
                  </span>
                </p>
              </div>
            </div>
            <p className="px-5 " style={{ textAlign: "justify" }}>
              <SafeHtmlComponent htmlContent={blogPost.content} />
            </p>
            <div class="disclaimer">
              <em>
                <strong>Disclaimer:</strong> As a service to our readers,
                Saaskin Corporation provides access to our website for archived
                content. Please note the date of last review or update on all
                articles. No content on this site, regardless of date, should
                ever be used as a substitute for direct medical advice from your
                doctor or other qualified clinician.
              </em>
            </div>
          </div>
        </div>
      </div>

      {/* <h2 style={{ textAlign: "center" }}>Related Blogs</h2>
      <div className="container mb-5 mt-4">
        <Slider {...relatedSettings}>
          {relatedBlogs && relatedBlogs.length > 0 ? (
            relatedBlogs.map((relatedPost) => (
              <div key={relatedPost.id}>
                <Link
                  to={`/Blogdetail/${relatedPost.id}/`}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <div
                    className="h-entry"
                    id="blog-related"
                    style={{ boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)" }}
                  >
                    <img
                      src={relatedPost.image}
                      alt={relatedPost.title}
                      className="img-blog"
                    />
                    <div className="container h-text-secondary h-text mt-2">
                      <p className="blog-category">
                        {relatedPost.blogSubcategory_name}
                      </p>
                      <h5 className="font-size-regular">
                        {relatedPost.title.length > 50
                          ? relatedPost.title.slice(0, 50) + "..."
                          : relatedPost.title}
                      </h5>
                      <div className="meta" style={{ color: "black" }}>
                        <span
                          style={{
                            color: "black",
                            marginRight: "5px",
                            fontStyle: "italic",
                          }}
                        >
                          Published:
                        </span>
                        <span style={{ fontStyle: "italic" }}>
                          {new Date(relatedPost.posted_date).toLocaleDateString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            }
                          )}
                        </span>
                      </div>
                      <p className="text-black mt-2">
                        {relatedPost.hint && relatedPost.hint.length > 55
                          ? relatedPost.hint.slice(0, 55) + "..."
                          : relatedPost.hint}
                      </p>
                      <p className="Learnmore" id="secLearnmore">
                        <Link to={`/Blogdetail/${relatedPost.id}`}>
                          Read More
                        </Link>
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
            ))
          ) : (
            <p className="text-center">No related blogs available.</p>
          )}
        </Slider>
      </div> */}

      <h2 style={{ textAlign: "center" }}>Related Blogs</h2>
      <div className="container mb-5 mt-4">
        {relatedBlogs && relatedBlogs.length > 1 ? (
          <Slider {...relatedSettings}>
            {relatedBlogs.map((relatedPost) => (
              <div key={relatedPost.id}>
                <Link
                  to={`/Blogdetail/${relatedPost.id}/`}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <div
                    className="h-entry"
                    id="blog-related"
                    style={{ boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)" }}
                  >
                    <img
                      src={relatedPost.image}
                      alt={relatedPost.title}
                      className="img-blog"
                    />
                    <div className="container h-text-secondary h-text mt-2">
                      <p className="blog-category">
                        {relatedPost.blogSubcategory_name}
                      </p>
                      <h5 className="font-size-regular">
                        {relatedPost.title.length > 50
                          ? relatedPost.title.slice(0, 50) + "..."
                          : relatedPost.title}
                      </h5>
                      <div className="meta" style={{ color: "black" }}>
                        <span
                          style={{
                            color: "black",
                            marginRight: "5px",
                            fontStyle: "italic",
                          }}
                        >
                          Published:
                        </span>
                        <span style={{ fontStyle: "italic" }}>
                          {new Date(relatedPost.posted_date).toLocaleDateString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            }
                          )}
                        </span>
                      </div>
                      <p className="text-black mt-2">
                        {relatedPost.hint && relatedPost.hint.length > 55
                          ? relatedPost.hint.slice(0, 55) + "..."
                          : relatedPost.hint}
                      </p>
                      <p className="Learnmore" id="secLearnmore">
                        <Link to={`/Blogdetail/${relatedPost.id}`}>
                          Read More
                        </Link>
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </Slider>
        ) : (
          <div className="d-flex justify-content-center">
            {relatedBlogs.map((relatedPost) => (
              <div
                key={relatedPost.id}
                className="col-md-3"
                style={{ margin: "0 10px" }}
              >
                <Link
                  to={`/Blogdetail/${relatedPost.id}/`}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <div
                    className="h-entry"
                    id="blog-related"
                    style={{
                      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    <img
                      src={relatedPost.image}
                      alt={relatedPost.title}
                      className="img-blog"
                      style={{ width: "100%", objectFit: "cover" }}
                    />
                    <div className="container h-text-secondary h-text mt-2">
                      <p className="blog-category">
                        {relatedPost.blogSubcategory_name}
                      </p>
                      <h5 className="font-size-regular">
                        {relatedPost.title.length > 50
                          ? relatedPost.title.slice(0, 50) + "..."
                          : relatedPost.title}
                      </h5>
                      <div className="meta" style={{ color: "black" }}>
                        <span
                          style={{
                            color: "black",
                            marginRight: "5px",
                            fontStyle: "italic",
                          }}
                        >
                          Published:
                        </span>
                        <span style={{ fontStyle: "italic" }}>
                          {new Date(relatedPost.posted_date).toLocaleDateString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            }
                          )}
                        </span>
                      </div>
                      <p className="text-black mt-2">
                        {relatedPost.hint && relatedPost.hint.length > 55
                          ? relatedPost.hint.slice(0, 55) + "..."
                          : relatedPost.hint}
                      </p>
                      <p className="Learnmore" id="secLearnmore">
                        <Link to={`/Blogdetail/${relatedPost.id}`}>
                          Read More
                        </Link>
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        )}
      </div>

      <Footer />
    </div>
  );
};

export default Blogdetail;

import { IoSearch } from "react-icons/io5";
import { FaYoutube } from "react-icons/fa6";
import { IoCall } from "react-icons/io5";
import { IoMdMail, IoLogoWhatsapp } from "react-icons/io";
import React, { useState, useEffect, useContext } from "react";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaPinterest,
  FaTelegram,
} from "react-icons/fa";
import "./Nav.css";
import Modal from "react-modal";
import { Link, useNavigate } from "react-router-dom";
import ReactFlagsSelect from "react-flags-select";

import {
  LoginUser,
  registerUser,
  OtpVerify,
  Search,
} from "../utils/ApiService";

import { toast } from "react-toastify";
import AxiosInstance from "../utils/AxiosInstance";

import swal from "sweetalert";
import Navthree from "./Navthree";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { ModalContext } from "./ModalContext";

Modal.setAppElement("#root");

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const [login_email, login_setEmail] = useState("");
  const [login_password, login_setPassword] = useState("");
  const { showModal, setShowModal } = useContext(ModalContext);
  const [showForgetPasswordModal, setShowForgetPasswordModal] = useState(false);
  const [showConfirmPasswordModal, setShowConfirmPasswordModal] =
    useState(false);
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mobile, setMobile] = useState("");
  const [password2, setPassword2] = useState("");
  const [showOTPDModal, setShowOTPDModal] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState(false);
  const [selected, setSelected] = useState("");
  const [otp, setOtp] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState(null);
  const [showiconPassword, setShowiconPassword] = useState(false);
  const [showloginiconPassword, setShowloginiconPassword] = useState(false);
  const [showloginiconPassword2, setShowloginiconPassword2] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();

    const loginData = { email: login_email, password: login_password };

    try {
      const response = await LoginUser(loginData);
      console.log("Login successful:", response);

      // Store the tokens in localStorage
      localStorage.setItem("accessToken", response.access_token);
      localStorage.setItem("refreshToken", response.refresh_token);

      // Close the login modal and show success message
      setShowModal(false);
      swal("Good job!", "Login successful!", "success");

      // Update state to reflect the user is logged in
      setIsLoggedIn(true);
      localStorage.setItem("isLoggedIn", "true");
    } catch (error) {
      console.error("Login failed:", error);
      if (error.response && error.response.status === 401) {
        swal("Oops!", "Invalid email or password. Please try again.", "error");
      } else {
        swal("Oops!", "Login failed. Please try again later.", "error");
      }
    }
  };

  const handleSignup = async (event) => {
    event.preventDefault();
    if (password.length < 8) {
      swal("Oops!", "Password must be at least 8 characters long.", "error");
      return;
    }
    if (password !== password2) {
      swal("Oops!", "Passwords do not match", "error");
      return;
    }

    const registerData = {
      first_name,
      last_name,
      country: selected,
      mobile,
      email,
      password,
      password2,
    };

    try {
      const response = await registerUser(registerData);
      console.log("Registration successful", response);
      setShowSignupModal(false);
      setShowOTPDModal(true);
      swal("Good job!", "Registration successful!", "success");
    } catch (error) {
      console.error("Error during user registration", error);
      swal("Oops!", "Registration failed. Please try again.", "error");
    }
  };

  const handleOtpVerify = async (event) => {
    event.preventDefault();
    const otpData = { otp };

    try {
      const response = await OtpVerify(otpData);
      console.log("OTP successful", response);
      setShowOTPDModal(false);
      swal("Good job!", "OTP successfully Verified!", "success");
      window.location.reload();
    } catch (error) {
      console.error("Error during OTP verification", error);
      swal("Oops!", "Failed to verify OTP. Please try again.", "error");
      window.location.reload();
    }
  };

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    if (!inputValue.trim()) {
      setError("Please enter a search term.");
      return;
    }
    try {
      const data = await Search(inputValue);
      console.log("Search Results:", data);
      setError(null);
      navigate("/SearchComponent", { state: { searchResults: data } });
    } catch (error) {
      console.error("Failed to fetch search results:", error);
      setError("Failed to fetch search results. Please try again later.");
    }
  };

  const openSignupModal = () => {
    setShowModal(false);
    setShowSignupModal(true);
  };

  const openLoginModal = () => {
    setShowSignupModal(false);
    setShowModal(true);
  };

  const openForgetPasswordModal = () => {
    setShowModal(false);
    setShowForgetPasswordModal(true);
  };

  const openConfirmPasswordModal = () => {
    setShowForgetPasswordModal(false);
    setShowConfirmPasswordModal(true);
  };

  const setShowloginModal = () => {
    setShowModal(true);
    setShowSignupModal(false);
  };

  const handleForgetPassword = async (e) => {
    e.preventDefault();
    if (email) {
      try {
        const res = await AxiosInstance.post("auth/password-reset/", {
          email: email,
        });
        if (res.status === 200) {
          console.log(res.data);
          toast.success(
            "A link to reset your password has been sent to your email"
          );
          setShowForgetPasswordModal(false);
          swal(
            "Success!",
            "Your email was sent successfully. Please check your inbox!",
            "success"
          );

          setTimeout(function () {
            window.location.reload();
          }, 3000);
        } else {
          toast.error("Failed to send password reset link");
          swal(
            "Uh-oh!",
            "Failed to send the email. Please try again later.",
            "error"
          );
        }
      } catch (error) {
        toast.error("An error occurred while sending the password reset link");
        console.error(error);
        swal("Oops!", "mail Failed to send. Please try again.", "error");
      }
      setEmail("");
    } else {
      toast.warn("Please enter a valid email address");
      swal(
        "Invalid Email",
        "The email address you entered is not valid. Please check and try again.",
        "warning"
      );
    }
  };
  const forgetclose = () => {
    setShowForgetPasswordModal(false);
    window.location.reload();
  };
  const loginclose = () => {
    setShowModal(false);
    window.location.reload();
  };

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const loggedInStatus = localStorage.getItem("isLoggedIn");
    if (loggedInStatus === "true") {
      setIsLoggedIn(true);
    }
  }, []);

  const handleLogout = () => {
    if (window.confirm("Are you sure you want to log out?")) {
      // Remove tokens from localStorage
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("isLoggedIn");

      // Update state to reflect the user is logged out
      setIsLoggedIn(false);
    }
  };

  const handelotpclose = () => {
    setShowOTPDModal(false);
    window.location.reload();
  };
  const togglePasswordVisibility1 = () => {
    setShowloginiconPassword(!showloginiconPassword);
  };
  const togglePasswordVisibility2 = () => {
    setShowloginiconPassword2(!showloginiconPassword2);
  };
  return (
    <div>
      <section className="d-none d-md-flex  align-items-center top-bar">
        <div className="container d-flex justify-content-between">
          <div className="contact-info d-flex align-items-center">
            <Link to="tel:+919940116677" className="contact-link">
              <IoCall /> +91-9940116677
            </Link>
            <Link to="mailto:info@saaskin.com" className="contact-link">
              <IoMdMail /> info@saaskin.com
            </Link>
          </div>

          <div className="google-img">
            <Link
              to={"https://g.co/kgs/Uy37BKf"}
              style={{ textDecoration: "none", color: "black" }}
            >
              <img
                src="/images/google.png"
                alt="rating"
                style={{ height: "15px" }}
              />
              <span>4.6</span>
              <img
                src="/images/star.png"
                alt="rating"
                style={{ height: "12px" }}
              />
            </Link>
          </div>

          <div className="social-links d-none d-md-flex align-items-center">
            <Link
              to="https://www.facebook.com/saaskincorporation"
              className="social-link"
            >
              <FaFacebook style={{ color: "	#1877F2" }} />
            </Link>
            <Link
              to="https://www.instagram.com/saaskincorporation/"
              className="social-link"
            >
              <FaInstagram style={{ color: "	#E1306C" }} />
            </Link>
            <Link
              to="https://www.linkedin.com/company/saask1n/about/"
              className="social-link"
            >
              <FaLinkedin style={{ color: "#0077B5" }} />
            </Link>
            <Link
              to="https://in.pinterest.com/saaskincorporation/"
              className="social-link"
              target="_blank"
            >
              <FaPinterest style={{ color: "	#E60023" }} />
            </Link>
            <Link
              to="https://www.youtube.com/@SaaskinCorporation/featured/"
              className="social-link"
            >
              <FaYoutube style={{ color: "	#FF0000" }} />
            </Link>
            <Link
              to="https://wa.me/+919940116677"
              target="_blank"
              className="social-link"
            >
              <IoLogoWhatsapp style={{ color: "#25D366" }} />
            </Link>
            <Link
              to="https://t.me/+919940116677"
              target="_blank"
              className="social-link"
            >
              <FaTelegram style={{ color: "#0088cc" }} />
            </Link>

            <Link
              to="/Blog"
              className="social-link"
              style={{ textDecoration: "none", fontWeight: "bold" }}
            >
              Blog
            </Link>
          </div>
        </div>
      </section>

      <header className="d-flex align-items-center main-header">
        <div className="container d-flex justify-content-between align-items-center head">
          <Link to="/" className="logo">
            <img src="/images/logo.webp" alt="Logo" />
          </Link>

          <form
            onSubmit={handleSearchSubmit}
            className="search-form d-none d-md-flex"
          >
            <input
              type="text"
              name="query"
              placeholder="Search..."
              className="form-control"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
            <button type="submit" className="search-button">
              <IoSearch />
            </button>
            {error && <p className="error-message">{error}</p>}
          </form>

          {!isLoggedIn ? (
            <>
              <button
                type="button"
                className="auth-button login d-none d-md-flex"
                data-bs-toggle="modal"
                data-bs-target="#loginModal"
                onClick={() => openLoginModal()}
              >
                Login
              </button>
              <button
                type="button"
                className="auth-button signup d-none d-md-flex"
                data-bs-toggle="modal"
                data-bs-target="#signupModal"
                onClick={() => openSignupModal()}
              >
                Signup
              </button>
            </>
          ) : (
            <button
              type="button"
              className="auth-button logout d-none d-md-flex"
              onClick={handleLogout}
            >
              Logout
            </button>
          )}

          <button className="toggle-button" onClick={toggleMenu}>
            {isMenuOpen ? "✕" : "☰"}
          </button>

          <div className={`menu ${isMenuOpen ? "open" : ""}`}>
            <form onSubmit={handleSearchSubmit} className="search-form">
              <input
                type="text"
                name="query"
                placeholder="Search..."
                className="form-controls"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
              />
              <button type="submit" className="search-button">
                <IoSearch />
              </button>
              {error && <p className="error-message">{error}</p>}
            </form>

            <div className="butt">
              {!isLoggedIn ? (
                <>
                  <button
                    type="button"
                    className="auth-button login"
                    data-bs-toggle="modal"
                    data-bs-target="#loginModal"
                    onClick={() => openLoginModal()}
                  >
                    LOGIN
                  </button>
                  <button
                    type="button"
                    className="auth-button signup"
                    data-bs-toggle="modal"
                    onClick={() => openSignupModal()}
                  >
                    SIGNUP
                  </button>
                </>
              ) : (
                <button
                  type="button"
                  className="auth-button logout"
                  onClick={handleLogout}
                >
                  LOGOUT
                </button>
              )}
            </div>

            <hr></hr>

            <div className="social-icons">
              <Link
                to="https://www.facebook.com/saaskincorporation"
                className="social-link"
              >
                <FaFacebook />
              </Link>
              <Link
                to="https://www.instagram.com/saaskincorporation/"
                className="social-link"
              >
                <FaInstagram />
              </Link>
              <Link
                to="https://www.linkedin.com/company/saask1n/about/"
                className="social-link"
              >
                <FaLinkedin />
              </Link>
              <Link to="#" className="social-link">
                <FaPinterest />
              </Link>
              <Link
                to="https://www.youtube.com/@SaaskinCorporation/featured/"
                className="social-link"
              >
                <FaYoutube />
              </Link>
              <Link
                to="https://wa.me/+919940116677 "
                target="_blank"
                className="social-link"
              >
                <IoLogoWhatsapp />
              </Link>
              <Link
                to="https://t.me/+919940116677"
                target="_blank"
                className="social-link"
              >
                <FaTelegram />
              </Link>
            </div>
          </div>
        </div>
      </header>
      <hr></hr>
      <Navthree />

      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        id="loginModal"
        tabIndex="-1"
        aria-labelledby="loginModalLabel"
        aria-hidden={!showModal}
        style={{ display: showModal ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Login
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={loginclose}
              ></button>
            </div>
            <div className="modal-body">
              <form id="loginForm" onSubmit={handleLogin}>
                <div className="mb-3">
                  <label className="col-form-label">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    value={login_email}
                    onChange={(e) => login_setEmail(e.target.value)}
                    placeholder="Enter Your Email"
                  />
                </div>
                <div className="mb-3">
                  <label className="col-form-label">Password</label>
                  <input
                    type={showloginiconPassword ? "text" : "password"}
                    className="form-control"
                    id="password"
                    value={login_password}
                    onChange={(e) => login_setPassword(e.target.value)}
                    placeholder="Enter Your Password"
                  />
                  <span
                    className="input-icon"
                    onClick={togglePasswordVisibility1}
                  >
                    {showloginiconPassword ? (
                      <AiFillEyeInvisible />
                    ) : (
                      <AiFillEye />
                    )}
                  </span>
                </div>
                <div className="modal-footer">
                  <button
                    type="submit"
                    className="login-btn"
                    id="loginButton"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Login
                  </button>
                </div>
              </form>
            </div>
            <div className="login-redirect">
              <p
                onClick={openSignupModal}
                style={{ cursor: "pointer", color: "blue" }}
              >
                <span style={{ cursor: "pointer", color: "black" }}>
                  Don't have an account?{" "}
                </span>
                Signup
              </p>
            </div>
            <div className="login-redirect">
              <p
                onClick={openForgetPasswordModal}
                style={{ cursor: "pointer", color: "blue" }}
              >
                Forget Password ?
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`modal fade ${showSignupModal ? "show" : ""}`}
        id="signupModal"
        tabIndex="-1"
        aria-labelledby="signupModalLabel"
        aria-hidden={!showSignupModal}
        style={{ display: showSignupModal ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Sign Up
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setShowSignupModal(false)}
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSignup}>
                <div className="mb-1">
                  <label htmlFor="firstName" className="col-form-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="first_name"
                    value={first_name}
                    onChange={(e) => setFirstName(e.target.value)}
                    placeholder="Enter Your First Name"
                  />
                </div>
                <div className="mb-1">
                  <label htmlFor="lastName" className="col-form-label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="last_name"
                    value={last_name}
                    onChange={(e) => setLastName(e.target.value)}
                    placeholder="Enter Your Last Name"
                  />
                </div>
                <div className="mb-1">
                  <label htmlFor="mobile" className="col-form-label">
                    Mobile
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="mobile"
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                    placeholder="Enter Your Mobile Number"
                  />
                </div>
                <div className="mb-1">
                  <label htmlFor="country" className="col-form-label">
                    Country
                  </label>
                  <ReactFlagsSelect
                    selected={selected}
                    onSelect={(code) => setSelected(code)}
                    searchable
                    searchPlaceholder="Search countries"
                  />
                </div>
                <div className="mb-1">
                  <label htmlFor="email" className="col-form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter Your Email"
                  />
                </div>
                <div className="mb-1">
                  <label htmlFor="password" className="col-form-label">
                    Password
                  </label>
                  <input
                    type={showloginiconPassword ? "text" : "password"}
                    className="form-control"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter Your Password"
                  />
                  <span
                    className="input-icon"
                    onClick={togglePasswordVisibility1}
                  >
                    {showloginiconPassword ? (
                      <AiFillEyeInvisible />
                    ) : (
                      <AiFillEye />
                    )}
                  </span>
                </div>
                <div className="mb-1">
                  <label htmlFor="password2" className="col-form-label">
                    Confirm Password
                  </label>
                  <input
                    type={showloginiconPassword2 ? "text" : "password"}
                    className="form-control"
                    id="password2"
                    value={password2}
                    onChange={(e) => setPassword2(e.target.value)}
                    placeholder="Enter Your confirm Password"
                  />

                  <span
                    className="input-icon"
                    onClick={togglePasswordVisibility2}
                  >
                    {showloginiconPassword2 ? (
                      <AiFillEyeInvisible />
                    ) : (
                      <AiFillEye />
                    )}
                  </span>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="signup-btn">
                    Sign Up
                  </button>
                </div>
              </form>
            </div>
            <div className="login-redirect">
              <p
                onClick={setShowloginModal}
                style={{ cursor: "pointer", color: "blue" }}
              >
                <span style={{ cursor: "pointer", color: "black" }}>
                  Do You have an account?{" "}
                </span>
                Signin
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`modal fade ${showOTPDModal ? "show" : ""}`}
        id="otpdModal"
        tabIndex="-1"
        aria-labelledby="otpModalLabel"
        aria-hidden={!showOTPDModal}
        style={{ display: showOTPDModal ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Enter Your OTP
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handelotpclose}
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleOtpVerify}>
                <div className="mb-3">
                  <label htmlFor="otp" className="col-form-label">
                    Enter Your OTP
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="otp"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    placeholder="Enter Your OTP"
                  />
                </div>
                <div className="modal-footer">
                  <button type="submit" className="login-btn">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`modal fade ${showForgetPasswordModal ? "show" : ""}`}
        id="ForgetPasswordModal"
        tabIndex="-1"
        aria-labelledby="showForgetPasswordModal"
        aria-hidden={!showForgetPasswordModal}
        style={{ display: showForgetPasswordModal ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Forget Password
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                // onClick={() => setShowForgetPasswordModal(false)}
                onClick={forgetclose}
              ></button>
            </div>

            <div className="modal-body">
              <form id="forgetPasswordForm" onSubmit={handleForgetPassword}>
                <div className="mb-3">
                  <label className="col-form-label">Email Address</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter Your Email"
                  />
                </div>

                <div className="modal-footer">
                  <button
                    type="submit"
                    className="login-btn"
                    id="loginButton"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Submit email
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;

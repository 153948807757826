// import React, { useState, useEffect } from "react";
// import Header from "../../maincomponents/Header";
// import Footer from "../../maincomponents/Footer";
// import { Link } from "react-router-dom";
// import axios from "axios";
// import Spinner from "../main/Spinner";
// import Slider from "react-slick";

// const Blog = () => {
//   const [blogPosts, setBlogPosts] = useState([]); // Initialize as empty array
//   const [blogPostsecondary, setBlogPostsecondary] = useState([]); // Initialize as empty array
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   // Pagination states for primary and secondary blogs
//   const [currentPagePrimary, setCurrentPagePrimary] = useState(1);
//   const [currentPageSecondary, setCurrentPageSecondary] = useState(1);

//   const postsPrimaryPage = 6; // Primary blog posts per page
//   const postsSecondaryPage = 15; // Secondary blog posts per page

//   useEffect(() => {
//     const fetchBlogPosts = async () => {
//       try {
//         const response = await axios.get(
//           `https://saaskin.com/api/auth/blogs-category/3/`
//         );

//         // Ensure the response is an array
//         const sortedPosts = Array.isArray(response.data)
//           ? response.data.sort(
//               (a, b) => new Date(b.posted_date) - new Date(a.posted_date)
//             )
//           : [];

//         setBlogPosts(sortedPosts);
//       } catch (error) {
//         console.error("Error fetching blog posts:", error);
//         setError("Failed to load blog posts."); // Set error message
//       } finally {
//         setLoading(false);
//       }
//     };

//     const fetchBlogPostsecondary = async () => {
//       try {
//         const response = await axios.get(
//           `https://saaskin.com/api/auth/blogs-category/4/`
//         );
//         const sortedPosts = response.data.sort(
//           (a, b) => new Date(b.posted_date) - new Date(a.posted_date)
//         );
//         setBlogPostsecondary(sortedPosts);
//       } catch (error) {
//         console.error("Error fetching blog posts:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchBlogPostsecondary();
//     fetchBlogPosts();
//   }, []);

//   // Pagination logic for primary blog
//   const indexOfLastPostPrimary = currentPagePrimary * postsPrimaryPage;
//   const indexOfFirstPostPrimary = indexOfLastPostPrimary - postsPrimaryPage;

//   const currentPostsPrimary = blogPosts.slice(
//     indexOfFirstPostPrimary,
//     indexOfLastPostPrimary
//   );

//   const paginatePrimary = (pageNumber) => setCurrentPagePrimary(pageNumber);

//   // Pagination logic for secondary blog
//   const indexOfLastPostSecondary = currentPageSecondary * postsSecondaryPage;
//   const indexOfFirstPostSecondary =
//     indexOfLastPostSecondary - postsSecondaryPage;
//   const currentPostsSecondary = blogPostsecondary.slice(
//     indexOfFirstPostSecondary,
//     indexOfLastPostSecondary
//   );

//   // Change page for secondary blog
//   const paginateSecondary = (pageNumber) => setCurrentPageSecondary(pageNumber);

//   const totalPagesSecondary = Math.ceil(
//     blogPostsecondary.length / postsSecondaryPage
//   );
//   const blogSettings = {
//     infinite: true,
//     speed: 500,
//     slidesToShow: 4,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 2000,
//     dots: true,
//     responsive: [
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//     ],
//   };

//   return (
//     <div>
//       {loading && <Spinner />}
//       <Header />

//       <section className="site-section mt-2">
//         <div id="blog-section">
//           <div className="row justify-content-center mb-4">
//             <div className="col-md-8 text-center">
//               <h2 className="text-black site-section-heading text-center mt-4 h1">
//                 Blog
//               </h2>
//             </div>
//           </div>

//           <div className="container">
//             <Slider {...blogSettings}>
//               {currentPostsPrimary.map((post) => (
//                 <div
//                   key={post.id}
//                   className="col-md-6 col-lg-3 mb-5 mb-lg-4 p-2"
//                 >
//                   <Link to={`/Blogdetail/${post.id}`}>
//                     <div
//                       className="h-entry"
//                       style={{
//                         boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
//                       }}
//                     >
//                       <img src={post.image} alt="Img" className="img-fluid" />
//                       <div className="container h-text mt-2">
//                         <h5 className="font-size-regular">
//                           {post.title.length > 50
//                             ? post.title.slice(0, 50) + "..."
//                             : post.title}
//                         </h5>
//                         <div className="meta" style={{ color: "black" }}>
//                           <span
//                             style={{
//                               color: "black",
//                               marginRight: "5px",
//                               fontStyle: "italic",
//                             }}
//                           >
//                             Published:
//                           </span>
//                           <span style={{ fontStyle: "italic" }}>
//                             {new Date(post.posted_date).toLocaleDateString(
//                               "en-US",
//                               {
//                                 year: "numeric",
//                                 month: "long",
//                                 day: "numeric",
//                               }
//                             )}
//                           </span>
//                         </div>
//                         <p className="text-black">
//                           {post.hint?.length > 55
//                             ? post.hint.slice(0, 55) + "..."
//                             : post.hint}
//                         </p>
//                         <p className="text-black Learnmore">
//                           <Link to={`/Blogdetail/${post.id}`}>Read More</Link>
//                         </p>
//                       </div>
//                     </div>
//                   </Link>
//                 </div>
//               ))}
//             </Slider>
//           </div>
//         </div>
//       </section>

//       <section className="site-section mt-1">
//         <div id="blog-section">
//           <div className="pagination justify-content-start align-items-center container ">
//             <div
//               className="pagination justify-content-center align-items-center"
//               style={{ textAlign: "center" }}
//             >
//               <span style={{ marginRight: "10px" }}>Page</span>

//               <button
//                 onClick={() => paginateSecondary(currentPageSecondary - 1)}
//                 disabled={currentPageSecondary === 1}
//                 className="page-item"
//                 style={{
//                   display: "inline-block",
//                   margin: "0 5px",
//                   padding: "10px",
//                   border: "none",
//                   borderRadius: "15px",
//                   backgroundColor: currentPageSecondary === 1 ? "#fff" : "#ccc",
//                   color: currentPageSecondary === 1 ? "#777" : "#000",
//                 }}
//               >
//                 {"<"}
//               </button>

//               {currentPageSecondary > 3 && (
//                 <>
//                   <button
//                     onClick={() => paginateSecondary(1)}
//                     className="page-item"
//                     style={{
//                       display: "inline-block",
//                       margin: "0 5px",
//                       padding: "10px",
//                       border: "none",
//                       borderRadius: "15px",
//                       backgroundColor: "#ccc",
//                       color: "#000",
//                       textAlign: "center",
//                     }}
//                   >
//                     1
//                   </button>
//                   {currentPageSecondary > 4 && (
//                     <span
//                       style={{
//                         display: "inline-block",
//                         margin: "0 5px",
//                         textAlign: "center",
//                       }}
//                     >
//                       first pg no
//                     </span>
//                   )}
//                 </>
//               )}

//               {/* Two pages before current page */}
//               {currentPageSecondary - 2 > 1 && (
//                 <button
//                   onClick={() => paginateSecondary(currentPageSecondary - 2)}
//                   className="page-item"
//                   style={{
//                     display: "inline-block",
//                     margin: "0 5px",
//                     padding: "10px",
//                     border: "none",
//                     borderRadius: "15px",
//                     backgroundColor: "#ccc",
//                     color: "#000",
//                     textAlign: "center",
//                   }}
//                 >
//                   {currentPageSecondary - 2}
//                 </button>
//               )}

//               {currentPageSecondary - 1 > 1 && (
//                 <button
//                   onClick={() => paginateSecondary(currentPageSecondary - 1)}
//                   className="page-item"
//                   style={{
//                     display: "inline-block",
//                     margin: "0 5px",
//                     padding: "10px",
//                     border: "none",
//                     borderRadius: "15px",
//                     backgroundColor: "#ccc",
//                     color: "#000",
//                     textAlign: "center",
//                   }}
//                 >
//                   {currentPageSecondary - 1}
//                 </button>
//               )}

//               {/* Current page */}
//               <button
//                 className="page-item active"
//                 style={{
//                   display: "inline-block",
//                   margin: "0 5px",
//                   padding: "10px",
//                   border: "none",
//                   borderRadius: "15px",
//                   backgroundColor: "#000",
//                   color: "#fff",
//                   textAlign: "center",
//                 }}
//               >
//                 {currentPageSecondary}
//               </button>

//               {/* Two pages after current page */}
//               {currentPageSecondary + 1 <= totalPagesSecondary && (
//                 <button
//                   onClick={() => paginateSecondary(currentPageSecondary + 1)}
//                   className="page-item"
//                   style={{
//                     display: "inline-block",
//                     margin: "0 5px",
//                     padding: "10px",
//                     border: "none",
//                     borderRadius: "15px",
//                     backgroundColor: "#ccc",
//                     color: "#000",
//                     textAlign: "center",
//                   }}
//                 >
//                   {currentPageSecondary + 1}
//                 </button>
//               )}

//               {currentPageSecondary + 2 <= totalPagesSecondary && (
//                 <button
//                   onClick={() => paginateSecondary(currentPageSecondary + 2)}
//                   className="page-item"
//                   style={{
//                     display: "inline-block",
//                     margin: "0 5px",
//                     padding: "10px",
//                     border: "none",
//                     borderRadius: "15px",
//                     backgroundColor: "#ccc",
//                     color: "#000",
//                     textAlign: "center",
//                   }}
//                 >
//                   {currentPageSecondary + 2}
//                 </button>
//               )}

//               {/* Ellipsis and Last page */}
//               {currentPageSecondary + 3 < totalPagesSecondary && (
//                 <span
//                   style={{
//                     display: "inline-block",
//                     margin: "0 5px",
//                     textAlign: "center",
//                   }}
//                 >
//                   last pg no
//                 </span>
//               )}

//               {currentPageSecondary < totalPagesSecondary && (
//                 <button
//                   onClick={() => paginateSecondary(totalPagesSecondary)}
//                   className="page-item"
//                   style={{
//                     display: "inline-block",
//                     margin: "0 5px",
//                     padding: "10px",
//                     border: "none",
//                     borderRadius: "15px",
//                     backgroundColor: "#ccc",
//                     color: "#000",
//                     textAlign: "center",
//                   }}
//                 >
//                   {totalPagesSecondary}
//                 </button>
//               )}

//               {/* Next button */}
//               <button
//                 onClick={() => paginateSecondary(currentPageSecondary + 1)}
//                 disabled={currentPageSecondary === totalPagesSecondary}
//                 className="page-item"
//                 style={{
//                   display: "inline-block",
//                   margin: "0 5px",
//                   padding: "10px",
//                   border: "none",
//                   borderRadius: "15px",
//                   backgroundColor:
//                     currentPageSecondary === totalPagesSecondary
//                       ? "#fff"
//                       : "#ccc",
//                   color:
//                     currentPageSecondary === totalPagesSecondary
//                       ? "#777"
//                       : "#000",
//                   textAlign: "center",
//                 }}
//               >
//                 {">"}
//               </button>
//             </div>
//           </div>
//           <div className="container mt-3">
//             <div className="row d-flex justify-content-center text-center">
//               {Array.isArray(currentPostsSecondary) &&
//               currentPostsSecondary.length > 0 ? (
//                 currentPostsSecondary.map((postsecondary) => (
//                   <div
//                     key={postsecondary.id}
//                     className="col-md-6 col-lg-3 mb-5 mb-lg-4"
//                   >
//                     <Link to={`/Blogdetail/${postsecondary.id}`}>
//                       <div
//                         className="h-entry"
//                         style={{
//                           boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
//                         }}
//                       >
//                         <img
//                           src={postsecondary.image}
//                           alt="Img"
//                           className="img-blog"
//                         />
//                         <div className="container h-text-secondary h-text mt-2">
//                           <p className="blog-category">
//                             {postsecondary.blogSubcategory_name}
//                           </p>
//                           <h5 className="font-size-regular">
//                             {postsecondary.title.length > 50
//                               ? postsecondary.title.slice(0, 50) + "..."
//                               : postsecondary.title}
//                           </h5>

//                           <div className="meta" style={{ color: "black" }}>
//                             <span
//                               style={{
//                                 color: "black",
//                                 marginRight: "5px",
//                                 fontStyle: "italic",
//                               }}
//                             >
//                               Published:
//                             </span>
//                             <span style={{ fontStyle: "italic" }}>
//                               {new Date(
//                                 postsecondary.posted_date
//                               ).toLocaleDateString("en-US", {
//                                 year: "numeric",
//                                 month: "long",
//                                 day: "numeric",
//                               })}
//                             </span>
//                           </div>

//                           <p className="text-black mt-2">
//                             {postsecondary.hint.length > 55
//                               ? postsecondary.hint.slice(0, 55) + "..."
//                               : postsecondary.hint}
//                           </p>

//                           <p className="Learnmore" id="secLearnmore">
//                             <Link to={`/Blogdetail/${postsecondary.id}`}>
//                               Read More
//                             </Link>
//                           </p>
//                         </div>
//                       </div>
//                     </Link>
//                   </div>
//                 ))
//               ) : (
//                 <p>No secondary blog posts available.</p>
//               )}
//             </div>
//           </div>

//           {/* Secondary Pagination controls */}
//           <div
//             className="pagination justify-content-center align-items-center"
//             style={{ textAlign: "center" }}
//           >
//             <span style={{ marginRight: "10px" }}>Page</span>

//             {/* Previous button */}
//             <button
//               onClick={() => paginateSecondary(currentPageSecondary - 1)}
//               disabled={currentPageSecondary === 1}
//               className="page-item"
//               style={{
//                 display: "inline-block",
//                 margin: "0 5px",
//                 padding: "10px",
//                 border: "none",
//                 borderRadius: "15px",
//                 backgroundColor: currentPageSecondary === 1 ? "#fff" : "#ccc",
//                 color: currentPageSecondary === 1 ? "#777" : "#000",
//               }}
//             >
//               {"<"}
//             </button>

//             {/* First page */}
//             {currentPageSecondary > 3 && (
//               <>
//                 <button
//                   onClick={() => paginateSecondary(1)}
//                   className="page-item"
//                   style={{
//                     display: "inline-block",
//                     margin: "0 5px",
//                     padding: "10px",
//                     border: "none",
//                     borderRadius: "15px",
//                     backgroundColor: "#ccc",
//                     color: "#000",
//                     textAlign: "center",
//                   }}
//                 >
//                   1
//                 </button>
//                 {currentPageSecondary > 4 && (
//                   <span
//                     style={{
//                       display: "inline-block",
//                       margin: "0 5px",
//                       textAlign: "center",
//                     }}
//                   >
//                     first pg no
//                   </span>
//                 )}
//               </>
//             )}

//             {/* Two pages before current page */}
//             {currentPageSecondary - 2 > 1 && (
//               <button
//                 onClick={() => paginateSecondary(currentPageSecondary - 2)}
//                 className="page-item"
//                 style={{
//                   display: "inline-block",
//                   margin: "0 5px",
//                   padding: "10px",
//                   border: "none",
//                   borderRadius: "15px",
//                   backgroundColor: "#ccc",
//                   color: "#000",
//                   textAlign: "center",
//                 }}
//               >
//                 {currentPageSecondary - 2}
//               </button>
//             )}

//             {currentPageSecondary - 1 > 1 && (
//               <button
//                 onClick={() => paginateSecondary(currentPageSecondary - 1)}
//                 className="page-item"
//                 style={{
//                   display: "inline-block",
//                   margin: "0 5px",
//                   padding: "10px",
//                   border: "none",
//                   borderRadius: "15px",
//                   backgroundColor: "#ccc",
//                   color: "#000",
//                   textAlign: "center",
//                 }}
//               >
//                 {currentPageSecondary - 1}
//               </button>
//             )}

//             {/* Current page */}
//             <button
//               className="page-item active"
//               style={{
//                 display: "inline-block",
//                 margin: "0 5px",
//                 padding: "10px",
//                 border: "none",
//                 borderRadius: "15px",
//                 backgroundColor: "#000",
//                 color: "#fff",
//                 textAlign: "center",
//               }}
//             >
//               {currentPageSecondary}
//             </button>

//             {/* Two pages after current page */}
//             {currentPageSecondary + 1 <= totalPagesSecondary && (
//               <button
//                 onClick={() => paginateSecondary(currentPageSecondary + 1)}
//                 className="page-item"
//                 style={{
//                   display: "inline-block",
//                   margin: "0 5px",
//                   padding: "10px",
//                   border: "none",
//                   borderRadius: "15px",
//                   backgroundColor: "#ccc",
//                   color: "#000",
//                   textAlign: "center",
//                 }}
//               >
//                 {currentPageSecondary + 1}
//               </button>
//             )}

//             {currentPageSecondary + 2 <= totalPagesSecondary && (
//               <button
//                 onClick={() => paginateSecondary(currentPageSecondary + 2)}
//                 className="page-item"
//                 style={{
//                   display: "inline-block",
//                   margin: "0 5px",
//                   padding: "10px",
//                   border: "none",
//                   borderRadius: "15px",
//                   backgroundColor: "#ccc",
//                   color: "#000",
//                   textAlign: "center",
//                 }}
//               >
//                 {currentPageSecondary + 2}
//               </button>
//             )}

//             {/* Ellipsis and Last page */}
//             {currentPageSecondary + 3 < totalPagesSecondary && (
//               <span
//                 style={{
//                   display: "inline-block",
//                   margin: "0 5px",
//                   textAlign: "center",
//                 }}
//               >
//                 last pg no
//               </span>
//             )}

//             {currentPageSecondary < totalPagesSecondary && (
//               <button
//                 onClick={() => paginateSecondary(totalPagesSecondary)}
//                 className="page-item"
//                 style={{
//                   display: "inline-block",
//                   margin: "0 5px",
//                   padding: "10px",
//                   border: "none",
//                   borderRadius: "15px",
//                   backgroundColor: "#ccc",
//                   color: "#000",
//                   textAlign: "center",
//                 }}
//               >
//                 {totalPagesSecondary}
//               </button>
//             )}

//             {/* Next button */}
//             <button
//               onClick={() => paginateSecondary(currentPageSecondary + 1)}
//               disabled={currentPageSecondary === totalPagesSecondary}
//               className="page-item"
//               style={{
//                 display: "inline-block",
//                 margin: "0 5px",
//                 padding: "10px",
//                 border: "none",
//                 borderRadius: "15px",
//                 backgroundColor:
//                   currentPageSecondary === totalPagesSecondary
//                     ? "#fff"
//                     : "#ccc",
//                 color:
//                   currentPageSecondary === totalPagesSecondary
//                     ? "#777"
//                     : "#000",
//                 textAlign: "center",
//               }}
//             >
//               {">"}
//             </button>
//           </div>
//         </div>
//       </section>

//       <Footer />
//     </div>
//   );
// };

// export default Blog;

import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Header from "../../maincomponents/Header";
import Footer from "../../maincomponents/Footer";
import { Link } from "react-router-dom";
import axios from "axios";
import Spinner from "../main/Spinner";
import Slider from "react-slick";

const Blog = () => {
  const [blogPosts, setBlogPosts] = useState([]); // Initialize as empty array
  const [blogPostsecondary, setBlogPostsecondary] = useState([]); // Initialize as empty array
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Pagination states for primary and secondary blogs
  const [currentPagePrimary, setCurrentPagePrimary] = useState(1);
  const [currentPageSecondary, setCurrentPageSecondary] = useState(1);

  const postsPrimaryPage = 6; // Primary blog posts per page
  const postsSecondaryPage = 16; // Secondary blog posts per page

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Fetch both data sources in parallel
        const [primaryResult, secondaryResult] = await Promise.allSettled([
          axios.get(`https://saaskin.com/api/auth/blogs-category/3/`),
          axios.get(`https://saaskin.com/api/auth/blogs-category/4/`),
        ]);

        // Process primary posts
        if (primaryResult.status === "fulfilled") {
          const sortedPrimary = Array.isArray(primaryResult.value.data)
            ? primaryResult.value.data.sort(
                (a, b) => new Date(b.posted_date) - new Date(a.posted_date)
              )
            : [];
          setBlogPosts(sortedPrimary);
        } else {
          console.error("Primary fetch error:", primaryResult.reason);
        }

        // Process secondary posts
        if (secondaryResult.status === "fulfilled") {
          const sortedSecondary = Array.isArray(secondaryResult.value.data)
            ? secondaryResult.value.data.sort(
                (a, b) => new Date(b.posted_date) - new Date(a.posted_date)
              )
            : [];
          setBlogPostsecondary(sortedSecondary);
        } else {
          console.error("Secondary fetch error:", secondaryResult.reason);
        }
      } catch (error) {
        console.error("Unexpected error:", error);
        setError("Failed to load blog posts.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Pagination logic for primary blog
  const indexOfLastPostPrimary = currentPagePrimary * postsPrimaryPage;
  const indexOfFirstPostPrimary = indexOfLastPostPrimary - postsPrimaryPage;

  const currentPostsPrimary = blogPosts.slice(
    indexOfFirstPostPrimary,
    indexOfLastPostPrimary
  );

  const paginatePrimary = (pageNumber) => setCurrentPagePrimary(pageNumber);

  // Pagination logic for secondary blog
  const indexOfLastPostSecondary = currentPageSecondary * postsSecondaryPage;
  const indexOfFirstPostSecondary =
    indexOfLastPostSecondary - postsSecondaryPage;
  const currentPostsSecondary = blogPostsecondary.slice(
    indexOfFirstPostSecondary,
    indexOfLastPostSecondary
  );

  // Change page for secondary blog
  const paginateSecondary = (pageNumber) => setCurrentPageSecondary(pageNumber);

  const totalPagesSecondary = Math.ceil(
    blogPostsecondary.length / postsSecondaryPage
  );
  const blogSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    dots: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      {loading && <Spinner />}
      <Header />

      <section className="site-section mt-2">
        <div id="blog-section">
          <div className="row justify-content-center mb-4">
            <div className="col-md-8 text-center">
              <h2 className="text-black site-section-heading text-center mt-4 h1">
                Blog
              </h2>
            </div>
          </div>

          <div className="container">
            <Slider {...blogSettings}>
              {currentPostsPrimary.map((post) => (
                <div
                  key={post.id}
                  className="col-md-6 col-lg-3 mb-5 mb-lg-4 p-2"
                >
                  <Link to={`/Blogdetail/${post.id}`}>
                    <div
                      className="h-entry"
                      style={{
                        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                      }}
                    >
                      <img src={post.image} alt="Img" className="img-fluid" />
                      <div className="container h-text mt-2">
                        <h5 className="font-size-regular">
                          {post.title.length > 50
                            ? post.title.slice(0, 50) + "..."
                            : post.title}
                        </h5>
                        <div className="meta" style={{ color: "black" }}>
                          <span
                            style={{
                              color: "black",
                              marginRight: "5px",
                              fontStyle: "italic",
                            }}
                          >
                            Published:
                          </span>
                          <span style={{ fontStyle: "italic" }}>
                            {new Date(post.posted_date).toLocaleDateString(
                              "en-US",
                              {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              }
                            )}
                          </span>
                        </div>
                        <p className="text-black">
                          {post.hint?.length > 55
                            ? post.hint.slice(0, 55) + "..."
                            : post.hint}
                        </p>
                        <p className="text-black Learnmore">
                          <Link to={`/Blogdetail/${post.id}`}>Read More</Link>
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>

      <section className="site-section mt-1">
        <div id="blog-section">
          <div className="pagination justify-content-start align-items-center container ">
            {/* <div
              className="pagination justify-content-center align-items-center"
              style={{ textAlign: "center" }}
            >
              <span style={{ marginRight: "10px" }}>Page</span>

              <button
                onClick={() => paginateSecondary(currentPageSecondary - 1)}
                disabled={currentPageSecondary === 1}
                className="page-item"
                style={{
                  display: "inline-block",
                  margin: "0 5px",
                  padding: "10px",
                  border: "none",
                  borderRadius: "15px",
                  backgroundColor: currentPageSecondary === 1 ? "#fff" : "#ccc",
                  color: currentPageSecondary === 1 ? "#777" : "#000",
                }}
              >
                {"<"}
              </button>

              {currentPageSecondary > 3 && (
                <>
                  <button
                    onClick={() => paginateSecondary(1)}
                    className="page-item"
                    style={{
                      display: "inline-block",
                      margin: "0 5px",
                      padding: "10px",
                      border: "none",
                      borderRadius: "15px",
                      backgroundColor: "#ccc",
                      color: "#000",
                      textAlign: "center",
                    }}
                  >
                    1
                  </button>
                  {currentPageSecondary > 4 && (
                    <span
                      style={{
                        display: "inline-block",
                        margin: "0 5px",
                        textAlign: "center",
                      }}
                    >
                      first pg no
                    </span>
                  )}
                </>
              )}

          
              {currentPageSecondary - 2 > 1 && (
                <button
                  onClick={() => paginateSecondary(currentPageSecondary - 2)}
                  className="page-item"
                  style={{
                    display: "inline-block",
                    margin: "0 5px",
                    padding: "10px",
                    border: "none",
                    borderRadius: "15px",
                    backgroundColor: "#ccc",
                    color: "#000",
                    textAlign: "center",
                  }}
                >
                  {currentPageSecondary - 2}
                </button>
              )}

              {currentPageSecondary - 1 > 1 && (
                <button
                  onClick={() => paginateSecondary(currentPageSecondary - 1)}
                  className="page-item"
                  style={{
                    display: "inline-block",
                    margin: "0 5px",
                    padding: "10px",
                    border: "none",
                    borderRadius: "15px",
                    backgroundColor: "#ccc",
                    color: "#000",
                    textAlign: "center",
                  }}
                >
                  {currentPageSecondary - 1}
                </button>
              )}

            
              <button
                className="page-item active"
                style={{
                  display: "inline-block",
                  margin: "0 5px",
                  padding: "10px",
                  border: "none",
                  borderRadius: "15px",
                  backgroundColor: "#000",
                  color: "#fff",
                  textAlign: "center",
                }}
              >
                {currentPageSecondary}
              </button>

             
              {currentPageSecondary + 1 <= totalPagesSecondary && (
                <button
                  onClick={() => paginateSecondary(currentPageSecondary + 1)}
                  className="page-item"
                  style={{
                    display: "inline-block",
                    margin: "0 5px",
                    padding: "10px",
                    border: "none",
                    borderRadius: "15px",
                    backgroundColor: "#ccc",
                    color: "#000",
                    textAlign: "center",
                  }}
                >
                  {currentPageSecondary + 1}
                </button>
              )}

              {currentPageSecondary + 2 <= totalPagesSecondary && (
                <button
                  onClick={() => paginateSecondary(currentPageSecondary + 2)}
                  className="page-item"
                  style={{
                    display: "inline-block",
                    margin: "0 5px",
                    padding: "10px",
                    border: "none",
                    borderRadius: "15px",
                    backgroundColor: "#ccc",
                    color: "#000",
                    textAlign: "center",
                  }}
                >
                  {currentPageSecondary + 2}
                </button>
              )}

             
              {currentPageSecondary + 3 < totalPagesSecondary && (
                <span
                  style={{
                    display: "inline-block",
                    margin: "0 5px",
                    textAlign: "center",
                  }}
                >
                  last pg no
                </span>
              )}

              {currentPageSecondary < totalPagesSecondary && (
                <button
                  onClick={() => paginateSecondary(totalPagesSecondary)}
                  className="page-item"
                  style={{
                    display: "inline-block",
                    margin: "0 5px",
                    padding: "10px",
                    border: "none",
                    borderRadius: "15px",
                    backgroundColor: "#ccc",
                    color: "#000",
                    textAlign: "center",
                  }}
                >
                  {totalPagesSecondary}
                </button>
              )}

             
              <button
                onClick={() => paginateSecondary(currentPageSecondary + 1)}
                disabled={currentPageSecondary === totalPagesSecondary}
                className="page-item"
                style={{
                  display: "inline-block",
                  margin: "0 5px",
                  padding: "10px",
                  border: "none",
                  borderRadius: "15px",
                  backgroundColor:
                    currentPageSecondary === totalPagesSecondary
                      ? "#fff"
                      : "#ccc",
                  color:
                    currentPageSecondary === totalPagesSecondary
                      ? "#777"
                      : "#000",
                  textAlign: "center",
                }}
              >
                {">"}
              </button>
            </div> */}
            <div className="mt-4">
              <Row>
                <Col>
                  <nav>
                    <ul className="pagination justify-content-center">
                      {/* Previous Button */}
                      <li className="page-item">
                        <button
                          onClick={() =>
                            paginateSecondary(currentPageSecondary - 1)
                          }
                          className="page-link"
                          disabled={currentPageSecondary === 1}
                        >
                          &laquo;
                        </button>
                      </li>

                      {/* First Page */}
                      <li className="page-item">
                        <button
                          onClick={() => paginateSecondary(1)}
                          className={`page-link ${
                            currentPageSecondary === 1 ? "active" : ""
                          }`}
                        >
                          1
                        </button>
                      </li>

                      {/* Ellipsis (if current page is far from the start) */}
                      {currentPageSecondary > 3 && (
                        <li className="page-item disabled">
                          <span className="page-link">...</span>
                        </li>
                      )}

                      {/* Middle Pages */}
                      {Array.from(
                        { length: totalPagesSecondary },
                        (_, i) => i + 1
                      )
                        .filter(
                          (number) =>
                            number > 1 &&
                            number < totalPagesSecondary &&
                            Math.abs(number - currentPageSecondary) <= 1
                        )
                        .map((number) => (
                          <li key={number} className="page-item">
                            <button
                              onClick={() => paginateSecondary(number)}
                              className={`page-link ${
                                currentPageSecondary === number ? "active" : ""
                              }`}
                            >
                              {number}
                            </button>
                          </li>
                        ))}

                      {/* Ellipsis (if current page is far from the end) */}
                      {currentPageSecondary < totalPagesSecondary - 2 && (
                        <li className="page-item disabled">
                          <span className="page-link">...</span>
                        </li>
                      )}

                      {/* Last Page */}
                      {totalPagesSecondary > 1 && (
                        <li className="page-item">
                          <button
                            onClick={() =>
                              paginateSecondary(totalPagesSecondary)
                            }
                            className={`page-link ${
                              currentPageSecondary === totalPagesSecondary
                                ? "active"
                                : ""
                            }`}
                          >
                            {totalPagesSecondary}
                          </button>
                        </li>
                      )}

                      {/* Next Button */}
                      <li className="page-item">
                        <button
                          onClick={() =>
                            paginateSecondary(currentPageSecondary + 1)
                          }
                          className="page-link"
                          disabled={
                            currentPageSecondary === totalPagesSecondary
                          }
                        >
                          &raquo;
                        </button>
                      </li>
                    </ul>
                  </nav>
                </Col>
              </Row>
            </div>
          </div>
          <div className="container mt-3">
            <div className="row d-flex justify-content-center text-center">
              {Array.isArray(currentPostsSecondary) &&
              currentPostsSecondary.length > 0 ? (
                currentPostsSecondary.map((postsecondary) => (
                  <div
                    key={postsecondary.id}
                    className="col-md-6 col-lg-3 mb-5 mb-lg-4"
                  >
                    <Link to={`/Blogdetail/${postsecondary.id}`}>
                      <div
                        className="h-entry"
                        style={{
                          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                        }}
                      >
                        <img
                          src={postsecondary.image}
                          alt="Img"
                          className="img-blog"
                        />
                        <div className="container h-text-secondary h-text mt-2">
                          <p className="blog-category">
                            {postsecondary.blogSubcategory_name}
                          </p>
                          <h5 className="font-size-regular">
                            {postsecondary.title.length > 50
                              ? postsecondary.title.slice(0, 50) + "..."
                              : postsecondary.title}
                          </h5>

                          <div className="meta" style={{ color: "black" }}>
                            <span
                              style={{
                                color: "black",
                                marginRight: "5px",
                                fontStyle: "italic",
                              }}
                            >
                              Published:
                            </span>
                            <span style={{ fontStyle: "italic" }}>
                              {new Date(
                                postsecondary.posted_date
                              ).toLocaleDateString("en-US", {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              })}
                            </span>
                          </div>

                          <p className="text-black mt-2">
                            {postsecondary.hint.length > 55
                              ? postsecondary.hint.slice(0, 55) + "..."
                              : postsecondary.hint}
                          </p>

                          <p className="Learnmore" id="secLearnmore">
                            <Link to={`/Blogdetail/${postsecondary.id}`}>
                              Read More
                            </Link>
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))
              ) : (
                <p>No secondary blog posts available.</p>
              )}
            </div>
          </div>

          {/* Secondary Pagination controls */}
          {/* <div
            className="pagination justify-content-center align-items-center"
            style={{ textAlign: "center" }}
          >
            <span style={{ marginRight: "10px" }}>Page</span>

           
            <button
              onClick={() => paginateSecondary(currentPageSecondary - 1)}
              disabled={currentPageSecondary === 1}
              className="page-item"
              style={{
                display: "inline-block",
                margin: "0 5px",
                padding: "10px",
                border: "none",
                borderRadius: "15px",
                backgroundColor: currentPageSecondary === 1 ? "#fff" : "#ccc",
                color: currentPageSecondary === 1 ? "#777" : "#000",
              }}
            >
              {"<"}
            </button>

           
            {currentPageSecondary > 3 && (
              <>
                <button
                  onClick={() => paginateSecondary(1)}
                  className="page-item"
                  style={{
                    display: "inline-block",
                    margin: "0 5px",
                    padding: "10px",
                    border: "none",
                    borderRadius: "15px",
                    backgroundColor: "#ccc",
                    color: "#000",
                    textAlign: "center",
                  }}
                >
                  1
                </button>
                {currentPageSecondary > 4 && (
                  <span
                    style={{
                      display: "inline-block",
                      margin: "0 5px",
                      textAlign: "center",
                    }}
                  >
                    first pg no
                  </span>
                )}
              </>
            )}

          
            {currentPageSecondary - 2 > 1 && (
              <button
                onClick={() => paginateSecondary(currentPageSecondary - 2)}
                className="page-item"
                style={{
                  display: "inline-block",
                  margin: "0 5px",
                  padding: "10px",
                  border: "none",
                  borderRadius: "15px",
                  backgroundColor: "#ccc",
                  color: "#000",
                  textAlign: "center",
                }}
              >
                {currentPageSecondary - 2}
              </button>
            )}

            {currentPageSecondary - 1 > 1 && (
              <button
                onClick={() => paginateSecondary(currentPageSecondary - 1)}
                className="page-item"
                style={{
                  display: "inline-block",
                  margin: "0 5px",
                  padding: "10px",
                  border: "none",
                  borderRadius: "15px",
                  backgroundColor: "#ccc",
                  color: "#000",
                  textAlign: "center",
                }}
              >
                {currentPageSecondary - 1}
              </button>
            )}

          
            <button
              className="page-item active"
              style={{
                display: "inline-block",
                margin: "0 5px",
                padding: "10px",
                border: "none",
                borderRadius: "15px",
                backgroundColor: "#000",
                color: "#fff",
                textAlign: "center",
              }}
            >
              {currentPageSecondary}
            </button>

           
            {currentPageSecondary + 1 <= totalPagesSecondary && (
              <button
                onClick={() => paginateSecondary(currentPageSecondary + 1)}
                className="page-item"
                style={{
                  display: "inline-block",
                  margin: "0 5px",
                  padding: "10px",
                  border: "none",
                  borderRadius: "15px",
                  backgroundColor: "#ccc",
                  color: "#000",
                  textAlign: "center",
                }}
              >
                {currentPageSecondary + 1}
              </button>
            )}

            {currentPageSecondary + 2 <= totalPagesSecondary && (
              <button
                onClick={() => paginateSecondary(currentPageSecondary + 2)}
                className="page-item"
                style={{
                  display: "inline-block",
                  margin: "0 5px",
                  padding: "10px",
                  border: "none",
                  borderRadius: "15px",
                  backgroundColor: "#ccc",
                  color: "#000",
                  textAlign: "center",
                }}
              >
                {currentPageSecondary + 2}
              </button>
            )}

          
            {currentPageSecondary + 3 < totalPagesSecondary && (
              <span
                style={{
                  display: "inline-block",
                  margin: "0 5px",
                  textAlign: "center",
                }}
              >
                last pg no
              </span>
            )}

            {currentPageSecondary < totalPagesSecondary && (
              <button
                onClick={() => paginateSecondary(totalPagesSecondary)}
                className="page-item"
                style={{
                  display: "inline-block",
                  margin: "0 5px",
                  padding: "10px",
                  border: "none",
                  borderRadius: "15px",
                  backgroundColor: "#ccc",
                  color: "#000",
                  textAlign: "center",
                }}
              >
                {totalPagesSecondary}
              </button>
            )}

        
            <button
              onClick={() => paginateSecondary(currentPageSecondary + 1)}
              disabled={currentPageSecondary === totalPagesSecondary}
              className="page-item"
              style={{
                display: "inline-block",
                margin: "0 5px",
                padding: "10px",
                border: "none",
                borderRadius: "15px",
                backgroundColor:
                  currentPageSecondary === totalPagesSecondary
                    ? "#fff"
                    : "#ccc",
                color:
                  currentPageSecondary === totalPagesSecondary
                    ? "#777"
                    : "#000",
                textAlign: "center",
              }}
            >
              {">"}
            </button>
          </div> */}

          {/* Secondary Pagination controls - Updated Design */}
          {/* <div className="mt-4">
            <Row>
              <Col>
                <nav>
                  <ul className="pagination justify-content-center">
                    <li className="page-item">
                      <button
                        onClick={() =>
                          paginateSecondary(currentPageSecondary - 1)
                        }
                        className="page-link"
                        disabled={currentPageSecondary === 1}
                      >
                        &laquo;
                      </button>
                    </li>
                    {Array.from(
                      { length: totalPagesSecondary },
                      (_, i) => i + 1
                    ).map((number) => (
                      <li key={number} className="page-item">
                        <button
                          onClick={() => paginateSecondary(number)}
                          className={`page-link ${
                            currentPageSecondary === number ? "active" : ""
                          }`}
                        >
                          {number}
                        </button>
                      </li>
                    ))}
                    <li className="page-item">
                      <button
                        onClick={() =>
                          paginateSecondary(currentPageSecondary + 1)
                        }
                        className="page-link"
                        disabled={currentPageSecondary === totalPagesSecondary}
                      >
                        &raquo;
                      </button>
                    </li>
                  </ul>
                </nav>
              </Col>
            </Row>
          </div> */}

          <div className="mt-4">
            <Row>
              <Col>
                <nav>
                  <ul className="pagination justify-content-center">
                    {/* Previous Button */}
                    <li className="page-item">
                      <button
                        onClick={() =>
                          paginateSecondary(currentPageSecondary - 1)
                        }
                        className="page-link"
                        disabled={currentPageSecondary === 1}
                      >
                        &laquo;
                      </button>
                    </li>

                    {/* First Page */}
                    <li className="page-item">
                      <button
                        onClick={() => paginateSecondary(1)}
                        className={`page-link ${
                          currentPageSecondary === 1 ? "active" : ""
                        }`}
                      >
                        1
                      </button>
                    </li>

                    {/* Ellipsis (if current page is far from the start) */}
                    {currentPageSecondary > 3 && (
                      <li className="page-item disabled">
                        <span className="page-link">...</span>
                      </li>
                    )}

                    {/* Middle Pages */}
                    {Array.from(
                      { length: totalPagesSecondary },
                      (_, i) => i + 1
                    )
                      .filter(
                        (number) =>
                          number > 1 &&
                          number < totalPagesSecondary &&
                          Math.abs(number - currentPageSecondary) <= 1
                      )
                      .map((number) => (
                        <li key={number} className="page-item">
                          <button
                            onClick={() => paginateSecondary(number)}
                            className={`page-link ${
                              currentPageSecondary === number ? "active" : ""
                            }`}
                          >
                            {number}
                          </button>
                        </li>
                      ))}

                    {/* Ellipsis (if current page is far from the end) */}
                    {currentPageSecondary < totalPagesSecondary - 2 && (
                      <li className="page-item disabled">
                        <span className="page-link">...</span>
                      </li>
                    )}

                    {/* Last Page */}
                    {totalPagesSecondary > 1 && (
                      <li className="page-item">
                        <button
                          onClick={() => paginateSecondary(totalPagesSecondary)}
                          className={`page-link ${
                            currentPageSecondary === totalPagesSecondary
                              ? "active"
                              : ""
                          }`}
                        >
                          {totalPagesSecondary}
                        </button>
                      </li>
                    )}

                    {/* Next Button */}
                    <li className="page-item">
                      <button
                        onClick={() =>
                          paginateSecondary(currentPageSecondary + 1)
                        }
                        className="page-link"
                        disabled={currentPageSecondary === totalPagesSecondary}
                      >
                        &raquo;
                      </button>
                    </li>
                  </ul>
                </nav>
              </Col>
            </Row>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Blog;
